import React from "react";
import Home from "../pages/home";
import Page from "@jumbo/shared/Page";
import Login from "app/pages/auth-pages/login/Login";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import Templates from "app/pages/templates/Templates";
import Images from "app/pages/media/images/Images";
import Videos from "app/pages/media/videos/Videos";
import Presentations from "app/pages/presentation/Presentations";
import EditPresentation from "app/pages/presentation/EditPresentation";
import MainModule from "app/pages/presentation/module/MainModule";
import EditBlock from "app/pages/presentation/block/EditBlock";
import Screens from "app/pages/screen/Screens";
import ArticleCategories from "app/pages/article-category/ArticleCategories";
import ScreenPresentations from "app/pages/screen/ScreenPresentations";
import Galleries from "app/pages/media/galleries/Galleries";
import Articles from "app/pages/article/Articles";
import Devices from "app/pages/device/Devices";
import EditGallery from "app/pages/media/galleries/EditGallery";
import Customers from "app/pages/customers/Customers";
import Products from "app/pages/product/Products";
import EditProduct from "app/pages/product/EditProduct";
import ProductCategories from "app/pages/product-category/ProductCategories";
import ProductAttributes from "app/pages/product-attribute/ProductAttributes";
import Menus from "app/pages/menu/Menus";
import EditMenu from "app/pages/menu/EditMenu";
import DailyOffers from "app/pages/daily-offer/DailyOffers";
import EditDailyOffer from "app/pages/daily-offer/EditDailyOffer";
import RemoteControlMenu from "app/pages/menu/RemoteControlMenu";
import RemoteControlOffer from "app/pages/daily-offer/RemoteControlOffer";
import EditDevice from "app/pages/device/EditDevice";
import DSOKDetails from "app/pages/customers/dsok/DSOKDetails";
import DSOKCategories from "app/pages/customers/dsok/dsok-category/DSOKCategories";
import DSOKProducts from "app/pages/customers/dsok/dsok-products/DSOKProducts";
import DSOKOrders from "app/pages/customers/dsok/dsok-orders/DSOKOrders";
import DSOKReports from "app/pages/customers/dsok/dsok-reports/DSOKReports";
import BuildAndDeploy from "app/pages/auth-pages/build-and-deploy/BuildAndDeploy";
import PartnerLogin from "app/pages/partner/PartnerLogin";
import DSOKProductAttributes from "app/pages/customers/dsok/dsok-product-attributes/DSOKProductAttributes";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
  {
    path: "/menu/remote-control/:customerId/:url",
    element: <Page component={RemoteControlMenu} layout={"solo-page"} />,
  },
  {
    path: "/daily-offer/remote-control/:customerId/:url",
    element: <Page component={RemoteControlOffer} layout={"solo-page"} />,
  },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  {
    path: "/",
    element: <Page component={Home} />,
  },
  {
    path: "/templates",
    element: <Page component={Templates} />,
  },
  {
    path: "/presentations/",
    element: <Page component={Presentations} />,
  },
  {
    path: "/screens/",
    element: <Page component={Screens} />,
  },
  {
    path: "/screen/:url",
    element: <Page component={ScreenPresentations} />,
  },
  {
    path: "/presentation/:url",
    element: <Page component={EditPresentation} />,
  },
  {
    path: "/presentation/:url/block/:blockUrl/",
    element: <Page component={EditBlock} />,
  },
  {
    path: "/presentation/:url/block/:blockUrl/module/:moduleUrl",
    element: <Page component={MainModule} />,
  },
  {
    path: "/media/images",
    element: <Page component={Images} />,
  },
  {
    path: "/media/videos",
    element: <Page component={Videos} />,
  },
  {
    path: "/media/galleries",
    element: <Page component={Galleries} />,
  },
  {
    path: "/media/gallery/:url",
    element: <Page component={EditGallery} />,
  },
  {
    path: "/kiosk/articles",
    element: <Page component={Articles} />,
  },
  {
    path: "/kiosk/article-categories",
    element: <Page component={ArticleCategories} />,
  },
  {
    path: "/kiosk/devices",
    element: <Page component={Devices} />,
  },
  {
    path: "/kiosk/device/:url",
    element: <Page component={EditDevice} />,
  },
  {
    path: "/products/",
    element: <Page component={Products} />,
  },
  {
    path: "/product/:url",
    element: <Page component={EditProduct} />,
  },
  {
    path: "/product-categories/",
    element: <Page component={ProductCategories} />,
  },
  {
    path: "/product-attributes/",
    element: <Page component={ProductAttributes} />,
  },
  {
    path: "/daily-offers/",
    element: <Page component={DailyOffers} />,
  },
  {
    path: "/daily-offer/:url",
    element: <Page component={EditDailyOffer} />,
  },
  {
    path: "/menus/",
    element: <Page component={Menus} />,
  },
  {
    path: "/menu/:url",
    element: <Page component={EditMenu} />,
  },
  {
    path: "/customer/dsok",
    element: <Page component={DSOKDetails} />,
  },
  {
    path: "/customer/dsok/categories",
    element: <Page component={DSOKCategories} />,
  },
  {
    path: "/customer/dsok/products",
    element: <Page component={DSOKProducts} />,
  },
  {
    path: "/customer/dsok/orders",
    element: <Page component={DSOKOrders} />,
  },
  {
    path: "/customer/dsok/product-attributes",
    element: <Page component={DSOKProductAttributes} />,
  },
  {
    path: "/customer/dsok/reports",
    element: <Page component={DSOKReports} />,
  },
];

const templateRoutes = [
  {
    path: "/templates",
    element: <Page component={Templates} />,
  },
];

const screenRoutes = [
  {
    path: "/screens/",
    element: <Page component={Screens} />,
  },
  {
    path: "/screen/:url",
    element: <Page component={ScreenPresentations} />,
  },
];

const presentationRoutes = [
  {
    path: "/presentations/",
    element: <Page component={Presentations} />,
  },
  {
    path: "/presentation/:url",
    element: <Page component={EditPresentation} />,
  },
  {
    path: "/presentation/:url/block/:blockUrl/",
    element: <Page component={EditBlock} />,
  },
  {
    path: "/presentation/:url/block/:blockUrl/module/:moduleUrl",
    element: <Page component={MainModule} />,
  },
];

const productRoutes = [
  {
    path: "/products/",
    element: <Page component={Products} />,
  },
  {
    path: "/product/:url",
    element: <Page component={EditProduct} />,
  },
  {
    path: "/product-categories/",
    element: <Page component={ProductCategories} />,
  },
  {
    path: "/product-attributes/",
    element: <Page component={ProductAttributes} />,
  },
  {
    path: "/daily-offers/",
    element: <Page component={DailyOffers} />,
  },
  {
    path: "/daily-offer/:url",
    element: <Page component={EditDailyOffer} />,
  },
  {
    path: "/menus/",
    element: <Page component={Menus} />,
  },
  {
    path: "/menu/:url",
    element: <Page component={EditMenu} />,
  },
];

const kioskRoutes = [
  {
    path: "/kiosk/articles",
    element: <Page component={Articles} />,
  },
  {
    path: "/kiosk/article-categories",
    element: <Page component={ArticleCategories} />,
  },
  {
    path: "/kiosk/devices",
    element: <Page component={Devices} />,
  },
];

const dsokRoutes = [
  {
    path: "/customer/dsok",
    element: <Page component={DSOKDetails} />,
  },
  {
    path: "/customer/dsok/categories",
    element: <Page component={DSOKCategories} />,
  },
  {
    path: "/customer/dsok/products",
    element: <Page component={DSOKProducts} />,
  },
  {
    path: "/customer/dsok/orders",
    element: <Page component={DSOKOrders} />,
  },
  {
    path: "/customer/dsok/product-attributes",
    element: <Page component={DSOKProductAttributes} />,
  },
  {
    path: "/customer/dsok/reports",
    element: <Page component={DSOKReports} />,
  },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: "/user/login",
    element: <Page component={Login} layout={"solo-page"} />,
  },
  {
    path: "/user/forgotPassword",
    element: <Page component={ForgotPassword} layout={"solo-page"} />,
  },
  {
    path: "/manage/partner-login",
    element: <Page component={PartnerLogin} layout={"solo-page"} />,
  },
];

/**
 routes only accessible for superadmin
 **/
const routesForSuperAdminOnly = [
  {
    path: "/customers",
    element: <Page component={Customers} />,
  },
  {
    path: "/dsok/customers",
    element: <Page component={BuildAndDeploy} />,
  },
  {
    path: "/customer/:url/dsok",
    element: <Page component={DSOKDetails} />,
  },
];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
  ...routesForSuperAdminOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
  routesForSuperAdminOnly,
  kioskRoutes,
  screenRoutes,
  templateRoutes,
  presentationRoutes,
  productRoutes,
  dsokRoutes,
};
