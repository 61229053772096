import React, { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Snackbar,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { useTranslation } from "react-i18next";
import authServices from "app/services/auth-services";
import usePartnerAuth from "@jumbo/hooks/usePartnerAuth";

const PartnerLogin = () => {
  const { setAuthToken } = useJumboAuth();
  const [openError, setOpenError] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { validate, user } = usePartnerAuth();
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const token = searchParams.get("token");

    if (token) {
      validate(token)
        .then((res) => {
          console.log("Token is valid", res);
          setValidated(true);
        })
        .catch((error) => {
          setValidated(false);
          console.log("Token validation failed", error);
        });
    }
  }, [searchParams]);

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: "1",
            position: "relative",
            background: `#0267a0`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: alpha("#0267a0", 0.65),
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={"h3"}
                color={"inherit"}
                fontWeight={500}
                mb={3}
              >
                User
              </Typography>
              <Typography variant={"body1"}>
                {validated && Object.keys(user).length
                  ? JSON.stringify(user)
                  : "Token is not validated"}
              </Typography>
            </Div>
          </Div>
        </CardContent>
      </Card>
    </Div>
  );
};

export default PartnerLogin;
