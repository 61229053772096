import jwtAuthKioskAxiosProduction from "../auth/jwtAuthKioskProduction";

export const validateToken = async (token) => {
  const { data } = await jwtAuthKioskAxiosProduction.post(
    "/partner/validateToken",
    {
      token,
    }
  );
  return data;
};
